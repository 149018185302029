import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, accountMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";
import RecipeCard from "../../components/recipeCard";

const uri = "/account/saved-recipes/";

const SavedRecipesPage = ({ location }) => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(filter: { categories: { nodes: { elemMatch: { name: { eq: "Camping" } } } } }) {
          nodes {
            __typename
            id
            slug
            uri
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300)
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  //console.log('allWpPost.nodes='+allWpPost.nodes);

  var recipe_list = [];
  if (true) {
    allWpPost.nodes.forEach((node) => {
      //console.log('node.slug='+node.slug);
      recipe_list[node.slug] = {
        //title: node.title,
        //excerpt: (node.excerpt.includes("INGREDIENTS") ? "" :node.excerpt),
        image: node.featuredImage,
      };
    });
  }
  //recipe_list[data.recipe_for_id].excerpt

  const userData = useSelector((state) => state.userData);

  const DataRow = ({ data, showGrid }) => {
    return showGrid ? (
      <RecipeCard
        key={data.recipe_for_id}
        title={data.recipe_name}
        uri={"/recipes/view/" + data.recipe_for_id}
        image={
          recipe_list[data.recipe_for_id].image === null
            ? null
            : recipe_list[data.recipe_for_id].image.node.localFile.childImageSharp.gatsbyImageData
        }
        excerpt={data.excerpt}
        className="recipeSearchCard"
      />
    ) : (
      <>
        <td className="crud-row-link">
          <Link to={"/recipes/view/" + data.recipe_for_id}>{data.recipe_name}</Link>
        </td>
        <td
          className="crud-row-link"
          dangerouslySetInnerHTML={{
            __html: data.excerpt.includes("INGREDIENTS") ? "" : data.excerpt,
          }}
        ></td>
      </>
    );
  };

  return (
    <Layout
      pageInfo={{ pageName: "user-saved-recipes" }}
      sideBarOptions={SideBarOptions(accountMenuLinks, uri, "My Account")}
    >
      <Seo title="Saved Recipes" keywords={[]} description="Saved Recipes" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={accountMenuLinks} currentLink={uri} menuTitle="My Account" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Saved Recipes</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row id="saved-recipes-crud">
          {isUserSignedIn(userData) ? (
            <CrudTable
              url="/saved-recipes"
              urlParams="recipe_for=ilic-recipe"
              customFilters={[{ name: "search_text", param_name: "search_text", default: "" }]}
              colHeaders={["Saved Recipes", "Description"]}
              rowComponent={(data, showGrid) => {
                return <DataRow data={data} showGrid={showGrid} />;
              }}
              gridOption={true}
            />
          ) : (
            <>Not logged in {navigate("/account/signin/")}</>
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export default SavedRecipesPage;
